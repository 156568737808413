import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        name: 'login',
        path: '/', 
        component: () => import('../components/SignIn.vue'),
    },
    {
        path: '/tournaments',
        name: 'tournaments',
        component: () => import('../components/Tournaments.vue'),
        props: { casinoId: 'BRPK' }
    },
    {
        path: '/casino/:casinoId/register/:tournamentId',
        name: 'register',
        component: () => import('../components/Register.vue'),
        props: true
    },
    {
        path: '/casino/:casinoId/register/:tournamentId/confirm/:registrationId',
        name: 'confirm',
        component: () => import('../components/Registration/Confirmation.vue'),
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
  });

export default router;