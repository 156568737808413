import router from './router/index'
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import AuthService from "@/services/auth.service";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL || 'http://localhost:18706/api/';

// Set our default for axios calls to the API
const API = axios.create({
    baseURL: apiBaseUrl,
    crossDomain: true
});

const refreshAuthLogic = failedRequest => AuthService.refreshToken().then(tokenRefreshResponse => {
    console.log('refreshAuthLogic: start', { failedRequest });
    console.log("Refreshing authentication token");
    localStorage.setItem('bpl_tokens', tokenRefreshResponse);
    failedRequest.response.config.headers.Authorization = 'Bearer ' + tokenRefreshResponse.jwt;
    return Promise.resolve();
}).catch(function (error) {
    console.log("Token authentication failed", { error });
    localStorage.removeItem("bpl_tokens");
    return router.push({ name: 'login', query: { returnUrl: router.currentRoute.fullPath } });
});

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(API, refreshAuthLogic, { pauseInstanceWhileRefreshing: true });

// Add the bearer token to all requests if we have it
// Add the bearer token and X-Api-Key to all requests if we have it
API.interceptors.request.use(async function (request) {

    // Determine if request.url is an absolute URL
    const isAbsoluteUrl = request.url.startsWith('http://') || request.url.startsWith('https://');

    // Construct the full URL: if request.url is not absolute, prepend the baseURL
    //const fullUrl = isAbsoluteUrl ? request.url : new URL(request.url, API.defaults.baseURL).href;
    const fullUrl = isAbsoluteUrl ? request.url : `${API.defaults.baseURL.replace(/\/$/, '')}/${request.url}`;

    // Check if the fullUrl starts with the apiBaseUrl before adding the Authorization header
    if (fullUrl.startsWith(apiBaseUrl)) {
        const apiKey = process.env.VUE_APP_API_KEY;
        request.headers['X-Api-Key'] = apiKey;

        const tokenResponse = await AuthService.getToken();
        if (tokenResponse.jwt) {
            request.withCredentials = true;
            request.headers.Authorization = `Bearer ${tokenResponse.jwt}`;
        }
    }

    return request;
});

// Add a response interceptor to handle 403 responses
API.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) {
            console.log("403 error - Redirecting to login");
            router.push({ name: 'login', query: { returnUrl: router.currentRoute.fullPath } });
        }
        return Promise.reject(error);
    }
);

export default API;