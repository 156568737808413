import API from "../API";
import axios from "axios";

class AuthService {

    googleAuthConfig;
    google;

    constructor() {

    }

    getToken() {
        let token = localStorage.getItem('bpl_tokens');

        if (token && token !== "undefined") {
            try {
                return Promise.resolve(JSON.parse(token));
            } catch (error) {
                return Promise.reject(error);
            }
        }

        // If we got this far we failed to get the token
        return Promise.resolve("");
    }

    login(user) {
        return API
            .post('users/authenticate',
                {
                    username: user.email,
                    password: user.password
                })
            .then(response => {
                if (response.data) {
                    localStorage.setItem('bpl_tokens', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    async refreshToken() {

        let userToken = await this.getToken().then(function (token) {
            if (!token) {
                return Promise.reject("Missing or empty token");
            }
            return token;
        });

        if(userToken) return Promise.resolve(userToken);

        // We need to use a new AXIOS instance because otherwise we get stuck in an infinite loop
        const refreshAPI = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL || 'http://localhost:18706/api/',
            withCredentials: true
        });

        return refreshAPI.post('tokens/refresh',
            {
                accessToken: userToken.accessToken
            }).then(response => {

            if (response.data.accessToken) {
                localStorage.setItem('refreshToken', JSON.stringify(response.data));
                return Promise.resolve(response.data);
            }

            // No access token so assume failure and reject the promise
            return Promise.reject()
        }).catch((error) => {
            console.warn("Cannot refresh token.");
            return Promise.reject(error)
        });
    }

    logout() {
        localStorage.removeItem('accessToken');
    }
}

export default new AuthService();