import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import API from './API'
import './assets/bpl.kiosk.css'
import moment from 'moment/moment'

let bpl = createApp(App);

bpl.use(router).mount('#app')

bpl.config.globalProperties.$api = { ...API }
bpl.config.globalProperties.$filters = {
    currencyUSD(value) {
      return '$' + value
    },
    formatDate(value, outputFormat = 'LLL', inputFormat = moment.ISO_8601) {
        // Create a moment object using the provided value and input format
        let date = moment(value, inputFormat);
  
        // If the date is not valid and an input format was provided, try again without the input format
        if (!date.isValid() && inputFormat !== moment.ISO_8601) {
          date = moment(value);
        }
  
        // If the date is still not valid, return an empty string or a default value
        if (!date.isValid()) {
          return '';
        }
  
        // Format and return the date
        return date.format(outputFormat);
      },
      formatCurrency(value) {
        // If the value is not a number or is undefined/null, return "$0.00"
        if (typeof value !== "number" || value === null || value === undefined) {
            value = 0;
        }
      
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        });
      
        return formatter.format(value);
      }
  }